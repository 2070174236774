var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "opacity": 1,
      "bg-color": "white",
      "spinner-variant": "secondary",
      "rounded": "sm",
      "z-index": "1000"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "90vh"
    }
  }, [_vm.isInitialized ? _c('b-row', {
    staticClass: "treeselect-container"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('p', {
    staticClass: "m-2"
  }, [_vm.mode != _vm.$constants.FORM_MODE.READONLY ? _c('b-form-checkbox', {
    attrs: {
      "id": "checkbox-1",
      "name": "checkbox-1",
      "checked": _vm.dataPermissionsCheckAll
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.toggleDataPermissions.apply(null, arguments);
      }
    }
  }, [_vm._v(" Data access permissions ")]) : _vm._e(), _vm.mode === _vm.$constants.FORM_MODE.READONLY ? _c('span', [_vm._v("Data access permissions")]) : _vm._e()], 1), _c('div', {
    staticClass: "h-400"
  }, [_c('treeselect', {
    staticClass: "treeselect",
    attrs: {
      "z-index": "1",
      "multiple": true,
      "open-direction": "bottom",
      "always-open": true,
      "options": _vm.placesTree.options
    },
    on: {
      "select": _vm.onPlacesTreeSelect,
      "deselect": _vm.onPlacesTreeDeselect
    },
    model: {
      value: _vm.placesTree.value,
      callback: function callback($$v) {
        _vm.$set(_vm.placesTree, "value", $$v);
      },
      expression: "placesTree.value"
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('p', {
    staticClass: "m-2"
  }, [_vm._v(" Territories permissions: ")]), _c('div', {
    staticClass: "h-400"
  }, [_c('treeselect', {
    staticClass: "treeselect",
    attrs: {
      "z-index": "1",
      "multiple": true,
      "open-direction": "bottom",
      "always-open": true,
      "options": _vm.territoriesTree.options
    },
    on: {
      "select": _vm.onTerritoriesTreeSelect,
      "deselect": _vm.onTerritoriesTreeDeselect
    },
    model: {
      value: _vm.territoriesTree.value,
      callback: function callback($$v) {
        _vm.$set(_vm.territoriesTree, "value", $$v);
      },
      expression: "territoriesTree.value"
    }
  })], 1)])], 1) : _vm._e(), _c('br'), _vm.isInitialized ? _c('b-row', {
    staticClass: "treeselect-container"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('p', {
    staticClass: "m-2"
  }, [_vm._v(" Tasks visibility: ")]), _c('div', {
    staticClass: "h-400"
  }, [_c('treeselect', {
    staticClass: "treeselect",
    attrs: {
      "z-index": "1",
      "multiple": true,
      "disable-branch-nodes": true,
      "open-direction": "bottom",
      "always-open": true,
      "options": _vm.groupsTree.options
    },
    on: {
      "select": _vm.onGroupsTreeSelect,
      "deselect": _vm.onGroupsTreeDeselect
    },
    model: {
      value: _vm.groupsTree.value,
      callback: function callback($$v) {
        _vm.$set(_vm.groupsTree, "value", $$v);
      },
      expression: "groupsTree.value"
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "h-400"
  }, [_c('menu-permissions-tree', {
    ref: "menuPermissionsTree",
    attrs: {
      "is-embeded": false,
      "mode": _vm.$constants.FORM_MODE.VIEW,
      "user-id": _vm.user_id
    }
  })], 1)])], 1) : _vm._e(), _vm.isEmbeded ? _c('b-row', [_c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Users'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'User submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'User submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1) : _vm._e()], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }