import Vue from "vue";

export function geoArrayToTree(data) {
  let root = [];

  //select countries
  root = [...new Set(data.map(item => item.country))].map(item => ({
    id: Vue.prototype.$helpers.uuidv4(),
    label: item
  }));

  root.forEach(country => {
    //select states
    country.children = [
      ...new Set(
        data.filter(d => d.country === country.label).map(i => i.state)
      )
    ].map(i => ({
      id: Vue.prototype.$helpers.uuidv4(),
      label: i
    }));

    country.children.forEach(state => {
      //select cities
      state.children = [
        ...new Set(
          data
            .filter(d => d.country === country.label && d.state === state.label)
            .map(i => i.city)
        )
      ].map(i => ({
        id: Vue.prototype.$helpers.uuidv4(),
        label: i
      }));
    });
  });

  //move United States to the top
  let index = root.findIndex(i => i.label === "United States");
  var element = root[index];
  root.splice(index, 1);
  root.splice(0, 0, element);

  return root;
}

export function getTreeLeafByName(root, label) {
  //let n = root.filter(i => i !== undefined).find(i => i.label === label);
  let n = root.find(i => i.label === label);

  if (!n) {
    for (let el in root) {
      if (!root[el].children) {
        return false;
      }
      let c = getTreeLeafByName(root[el].children, label);

      if (c !== false) {
        return {
          id: c.id,
          label: c.label
        };
      }
    }
  } else {
    return {
      id: n.id,
      label: n.label
    };
  }
}
/*
export function getTreeLeafById(root, label) {

    let n = root.find(i => i.label === label);

    if (!n) {
        for (let el in root) {
            if (!root[el].children) {
                return false;
            }
            let c = getTreeLeafByName(root[el].children, label);

            if (c !== false) {

                return {
                    id: c.id,
                    label: c.label,
                };
            }
        }
    } else {

        return {
            id: n.id,
            label: n.label,
        }
    }
}
*/
export function getAscendingBranch(root, id) {
  let n = root.find(i => i.id === id);

  if (!n) {
    for (let el in root) {
      if (!root[el].children) {
        return false;
      }

      let c = getAscendingBranch(root[el].children, id);

      if (c !== false) {
        return {
          id: root[el].id,
          label: root[el].label,
          children: new Array(c)
        };
      }
    }
  } else {
    return n;
  }
}

export function getAscendingBranchAsArray(root, id, level, result) {
  let _propName = "";

  level++;

  if (level === 3) _propName = "city";
  if (level === 2) _propName = "state";
  if (level === 1) _propName = "country";

  let n = root.find(i => i.id === id);

  if (!n) {
    for (let el in root) {
      if (!root[el].children) {
        return false;
      }

      let c = getAscendingBranchAsArray(root[el].children, id, level, result);

      if (c !== false) {
        result[_propName] = root[el].label;

        return result;
      }
    }
  } else {
    result[_propName] = n.label;

    return result;
  }
}

/*
export function mergeBranches(a, b) {

    let c = {
        id: a.id,
        label: a.label,
        children: [...a.children, ...b.children]
    };

    return c;

}
*/
