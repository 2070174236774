<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="p-3">
        <b-row v-if="$isMobile">
          <b-col>
            <multiselect
              v-model="selectedUser"
              track-by="value"
              label="text"
              :options="users.options"
              deselect-label=""
              select-label=""
              placeholder=""
              :multiple="false"
              @input="onUserDropdownInput"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="2" md="12" sm="12" v-if="!$isMobile">
            <b-overlay
              :show="isLoading"
              :opacity="0.5"
              spinner-variant="secondary"
              rounded="sm"
              z-index="1000"
              :class="[isLoading ? 'pt-5' : '']"
            >
              <perfect-scrollbar :options="{ suppressScrollX: true }">
                <b-list-group style="height:80vh;">
                  <template v-for="(user, index) in users.options">
                    <b-list-group-item
                      :key="index"
                      button
                      @click="onUserItemClick(user)"
                      :variant="users.selected === user.value ? 'primary' : ''"
                      >{{ user.text }}
                    </b-list-group-item>
                  </template>
                </b-list-group>
              </perfect-scrollbar>
            </b-overlay>
          </b-col>
          <b-col>
            <perfect-scrollbar :options="{ suppressScrollX: true }">
              <user-permissions-form
                ref="permissionsForm"
                :is-embeded="false"
                :mode="FORM_MODE.VIEW"
                :user-id="users.selected"
              />
            </perfect-scrollbar>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import { FORM_MODE } from "@/shared/constants.js";

import UserPermissionsForm from "@/views/UserManagement/UserPermissionsForm";

export default {
  name: "UsersPermissions",
  components: {
    UserPermissionsForm,
    Multiselect
  },
  data: function() {
    return {
      FORM_MODE: FORM_MODE,
      isLoading: false,
      selectedUser: {},
      users: {
        selected: "",
        options: []
      }
    };
  },
  computed: {},
  mounted() {
    let self = this;
    self.isLoading = true;
    this.$api.get("dictionaries/users").then(response => {
      self.isLoading = false;
      self.users.options = response.map(u => ({
        value: u.id,
        text: `${u.full_name} (${u.Role})`
      }));
    });
  },
  methods: {
    onUserDropdownInput(value) {
      this.selectedUser = value;
      this.users.selected = value.value;
      this.$refs.permissionsForm.initialize(this.users.selected);
    },
    onUserItemClick(e) {
      this.users.selected = e.value;
      this.$refs.permissionsForm.initialize(this.users.selected);
    }
  },
  watch: {
    /*
    "users.selected"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$refs.permissionsForm.initialize(newValue.value || newValue);
      }
    }
    */
  }
};
</script>

<style scoped>
.card {
  height: 100vh;
}
</style>
