var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "p-3",
    attrs: {
      "fluid": ""
    }
  }, [_vm.$isMobile ? _c('b-row', [_c('b-col', [_c('multiselect', {
    attrs: {
      "track-by": "value",
      "label": "text",
      "options": _vm.users.options,
      "deselect-label": "",
      "select-label": "",
      "placeholder": "",
      "multiple": false
    },
    on: {
      "input": _vm.onUserDropdownInput
    },
    model: {
      value: _vm.selectedUser,
      callback: function callback($$v) {
        _vm.selectedUser = $$v;
      },
      expression: "selectedUser"
    }
  })], 1)], 1) : _vm._e(), _c('b-row', [!_vm.$isMobile ? _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-overlay', {
    class: [_vm.isLoading ? 'pt-5' : ''],
    attrs: {
      "show": _vm.isLoading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm",
      "z-index": "1000"
    }
  }, [_c('perfect-scrollbar', {
    attrs: {
      "options": {
        suppressScrollX: true
      }
    }
  }, [_c('b-list-group', {
    staticStyle: {
      "height": "80vh"
    }
  }, [_vm._l(_vm.users.options, function (user, index) {
    return [_c('b-list-group-item', {
      key: index,
      attrs: {
        "button": "",
        "variant": _vm.users.selected === user.value ? 'primary' : ''
      },
      on: {
        "click": function click($event) {
          return _vm.onUserItemClick(user);
        }
      }
    }, [_vm._v(_vm._s(user.text) + " ")])];
  })], 2)], 1)], 1)], 1) : _vm._e(), _c('b-col', [_c('perfect-scrollbar', {
    attrs: {
      "options": {
        suppressScrollX: true
      }
    }
  }, [_c('user-permissions-form', {
    ref: "permissionsForm",
    attrs: {
      "is-embeded": false,
      "mode": _vm.FORM_MODE.VIEW,
      "user-id": _vm.users.selected
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }