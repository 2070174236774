<template>
  <b-container fluid>
    <b-overlay
      :show="isLoading"
      :opacity="1"
      bg-color="white"
      spinner-variant="secondary"
      rounded="sm"
      z-index="1000"
    >
      <div style="height: 90vh">
        <b-row class="treeselect-container" v-if="isInitialized">
          <b-col cols="12" lg="6">
            <p class="m-2">
              <b-form-checkbox
                v-if="mode != $constants.FORM_MODE.READONLY"
                id="checkbox-1"
                name="checkbox-1"
                :checked="dataPermissionsCheckAll"
                @click.native.prevent="toggleDataPermissions"
              >
                Data access permissions
              </b-form-checkbox>
              <span v-if="mode === $constants.FORM_MODE.READONLY"
                >Data access permissions</span
              >
            </p>
            <div class="h-400">
              <treeselect
                class="treeselect"
                v-model="placesTree.value"
                z-index="1"
                :multiple="true"
                open-direction="bottom"
                :always-open="true"
                :options="placesTree.options"
                @select="onPlacesTreeSelect"
                @deselect="onPlacesTreeDeselect"
              />
            </div>
          </b-col>

          <b-col cols="12" lg="6">
            <p class="m-2">
              Territories permissions:
            </p>
            <div class="h-400">
              <treeselect
                class="treeselect"
                v-model="territoriesTree.value"
                z-index="1"
                :multiple="true"
                open-direction="bottom"
                :always-open="true"
                :options="territoriesTree.options"
                @select="onTerritoriesTreeSelect"
                @deselect="onTerritoriesTreeDeselect"
              />
            </div>
          </b-col>
        </b-row>
        <br />

        <b-row class="treeselect-container" v-if="isInitialized">
          <b-col cols="12" lg="6">
            <p class="m-2">
              Tasks visibility:
            </p>
            <div class="h-400">
              <treeselect
                class="treeselect"
                v-model="groupsTree.value"
                z-index="1"
                :multiple="true"
                :disable-branch-nodes="true"
                open-direction="bottom"
                :always-open="true"
                :options="groupsTree.options"
                @select="onGroupsTreeSelect"
                @deselect="onGroupsTreeDeselect"
              />
            </div>
          </b-col>

          <b-col cols="12" lg="6">
            <div class="h-400">
              <menu-permissions-tree
                ref="menuPermissionsTree"
                :is-embeded="false"
                :mode="$constants.FORM_MODE.VIEW"
                :user-id="user_id"
              />
            </div>
          </b-col>
        </b-row>

        <b-row v-if="isEmbeded">
          <hr />
          <form-submission-actions
            :mode="mode"
            :loading="{
              save: saveInProgress,
              saveAndView: saveAndViewInProgress
            }"
            :buttons-visibility="{
              previous: $customTable.getPrevKey($route.meta.module, id),
              next: $customTable.getNextKey($route.meta.module, id)
            }"
            @previous-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getPrevKey($route.meta.module, id)
                }
              })
            "
            @next-item="
              $router.push({
                name: $route.name,
                params: {
                  action: $route.params.action,
                  id: $customTable.getNextKey($route.meta.module, id)
                }
              })
            "
            @save="
              save('tabular').then(response =>
                response ? $router.push({ name: 'Users' }) : false
              )
            "
            @save-and-view="
              save('view').then(response =>
                response
                  ? $router.push({
                      name: 'User submission',
                      params: { action: 'view', id: response }
                    })
                  : false
              )
            "
            @edit="
              $router.push({
                name: 'User submission',
                params: { action: 'edit', id: id }
              })
            "
            @back="$router.push($store.getters['router/previousRoute'])"
          />
        </b-row>
      </div>
    </b-overlay>
  </b-container>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import FormSubmissionActions from "@/components/FormSubmissionActions";
import MenuPermissionsTree from "./MenuPermissions/MenuPermissionsTree.vue";

import {
  getAscendingBranchAsArray,
  getTreeLeafByName,
  geoArrayToTree
} from "@/shared/data-permissions-helpers";

import { mapState } from "vuex";

export default {
  props: {
    id: {
      type: [Number, String],
      default: 0
    },
    mode: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    isEmbeded: {
      type: Boolean,
      default: true
    }
  },
  name: "UserPermissionsForm",
  components: {
    Treeselect,
    FormSubmissionActions,
    MenuPermissionsTree
  },
  data: function() {
    return {
      user_id: undefined,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      isInitialized: false,
      controls: {},
      territoriesTree: {
        value: null,
        options: []
      },
      placesTree: {
        value: null,
        options: []
      },
      groupsTree: {
        value: null,
        options: []
      },
      dataPermissionsCheckAll: false
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),

  created() {},
  mounted() {
    if (!this.id) return;

    this.user_id = this.id;

    this.initialize(this.user_id);
  },
  methods: {
    initialize(_uid = false) {
      if (_uid !== false) {
        this.user_id = _uid;
      }

      if (this.user_id === 0) return;

      this.getGeoMapping();

      this.getGroupPermissions();

      this.updateControlsState();
    },
    async getGroupPermissions() {
      let self = this;

      let error, groups;

      //fetch groups tree
      [error, groups] = await this.$to(
        this.$api.get(`user/${this.user_id}/groups/mapped`)
      );

      if (error) throw new Error(error);

      let gtree = [];

      groups.forEach(g => {
        g.users = g.users.map(u => ({
          id: this.$helpers.uuidv4(),
          user_id: u.id,
          label: u.label
        }));

        gtree.push({
          id: g.id,
          label: g.group,
          children: g.users
        });
      });

      self.groupsTree.options = gtree;

      //disable tree if form is read only
      if (this.mode === this.$constants.FORM_MODE.READONLY) {
        self.groupsTree.options.forEach(n => {
          n.isDisabled = true;
        });
      }

      this.markAllowedGroupUsers();
    },
    selectedGroupPermissions2Array() {
      let self = this;

      let result = [];

      self.groupsTree.options.forEach(group => {
        group.children.forEach(children => {
          //if viewer is allowed then add unique leaf id
          if (self.groupsTree.value.includes(children.id)) {
            result.push({
              group_id: group.id,
              user_id: this.user_id,
              viewer_id: children.user_id
            });
          }
        });
      });

      return result;
    },
    async getGeoMapping() {
      let self = this;

      try {
        this.isLoading = true;

        let error, places;

        //fetch geo tree
        [error, places] = await this.$to(
          this.$api.post("dictionaries/countries/mapped", {
            ignore_restrictions: true
          })
        );

        if (error) throw new Error(error);

        self.placesTree.options = geoArrayToTree(places);

        //disable tree if form is read only
        if (this.mode === this.$constants.FORM_MODE.READONLY) {
          self.placesTree.options.forEach(n => {
            n.isDisabled = true;
          });
        }

        //fetch territories list
        let territories;
        [error, territories] = await this.$to(this.$api.get("territories"));

        if (error) throw new Error(error);

        self.territoriesTree.options = territories.map(t => ({
          id: t.ID,
          label: t.Name
        }));

        //disable tree if form is read only
        if (this.mode === this.$constants.FORM_MODE.READONLY) {
          self.territoriesTree.options.forEach(n => {
            n.isDisabled = true;
          });
        }

        await this.markAllowedPlaces();

        await this.markAllowedTerritories();

        this.dataPermissionsCheckAll = this.placesTree.value.length > 0;
      } catch (error) {
        self.$form.msgBoxOk(error.message);
      } finally {
        this.isLoading = false;
        this.isInitialized = true;
      }
    },
    async markAllowedGroupUsers() {
      let self = this;
      let error, groups;

      [error, groups] = await this.$to(
        this.$api.get(`user/${this.user_id}/permissions/groups`)
      );

      if (error) throw new Error(error);

      //find in the tree, get id, add to selected values
      self.groupsTree.value = [];

      groups.forEach(item => {
        self.groupsTree.options.forEach(branch => {
          branch.children.forEach(leaf => {
            //if viewer is allowed then add unique leaf id
            if (
              parseInt(item.group_id) === branch.id &&
              leaf.user_id === parseInt(item.viewer_id)
            ) {
              self.groupsTree.value.push(leaf.id);
            }
          });
        });
      });

      //self.groupsTree.value = [...new Set(self.groupsTree.value)]
    },
    async markAllowedPlaces() {
      let self = this;
      let error, places;

      [error, places] = await this.$to(
        this.$api.get(`user/${this.user_id}/permissions/data`)
      );

      if (error) throw new Error(error);

      //find in the tree, get id, add to selected values

      self.placesTree.value = [];

      places.forEach(item => {
        let leaf = {};

        if (item.city) {
          leaf = getTreeLeafByName(self.placesTree.options, item.city);
        }
        if (!item.city && item.state) {
          leaf = getTreeLeafByName(self.placesTree.options, item.state);
        }
        if (!item.city && !item.state && item.country) {
          leaf = getTreeLeafByName(self.placesTree.options, item.country);
        }

        if (leaf.id) {
          //add unique

          if (!self.placesTree.value.find(i => i === leaf.id)) {
            self.placesTree.value.push(leaf.id);
          }
        }
      });
    },
    async markAllowedTerritories() {
      let self = this;
      let error, territories;

      [error, territories] = await this.$to(
        this.$api.get(`user/${this.user_id}/permissions/territories`)
      );

      if (error) throw new Error(error);

      //find in the tree, get id, add to selected values

      self.territoriesTree.value = [];

      territories.forEach(item => {
        let leaf = {};

        leaf = getTreeLeafByName(self.territoriesTree.options, item.name);

        if (leaf.id) {
          //add unique

          if (!self.territoriesTree.value.find(i => i === leaf.id)) {
            self.territoriesTree.value.push(leaf.id);
          }
        }
      });
    },
    onPlacesTreeSelect(value) {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return;

      if (!this.placesTree.value) this.placesTree.value = [];

      this.placesTree.value.push(value.id);

      this.updateDataPermissions();
    },
    onPlacesTreeDeselect(value) {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return;

      this.placesTree.value = this.placesTree.value.filter(v => v !== value.id);

      this.updateDataPermissions();
    },
    onTerritoriesTreeSelect(value) {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return;

      if (!this.territoriesTree.value) this.territoriesTree.value = [];

      this.territoriesTree.value.push(value.id);

      this.updateTerritoriesPermissions();
    },
    onTerritoriesTreeDeselect(value) {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return;

      this.territoriesTree.value = this.territoriesTree.value.filter(
        v => v !== value.id
      );

      this.updateTerritoriesPermissions();
    },
    onGroupsTreeSelect(value) {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return;

      if (!this.groupsTree.value) this.groupsTree.value = [];

      //select this user in all groups
      this.groupsTree.options.forEach(item => {
        item.children.forEach(children => {
          if (children.user_id === value.user_id) {
            this.groupsTree.value.push(children.id);
          }
        });
      });

      this.updateGroupsPermissions();
    },
    onGroupsTreeDeselect(value) {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return;

      this.groupsTree.value = this.groupsTree.value.filter(v => v !== value.id);

      this.updateGroupsPermissions();
    },
    async updateGroupsPermissions() {
      let self = this;

      try {
        let payload = {
          data: this.selectedGroupPermissions2Array()
        };

        let error, response;

        [error, response] = await this.$to(
          this.$api.put(`user/${this.user_id}/permissions/groups`, payload)
        );

        if (error) throw new Error(error);

        this.$form.makeToastInfo(response.message);

        return response;
      } catch (error) {
        self.$form.makeToastError(error.message);
      } finally {
        this.isLoading = false;
      }
    },
    updateDataPermissions() {
      let self = this,
        data = [];

      this.placesTree.value.forEach(v => {
        let row = getAscendingBranchAsArray(self.placesTree.options, v, 0, {});

        data.push(row);
      });

      let payload = {
        data: data
      };

      return this.saveDataPermissions(payload);
    },
    saveDataPermissions(payload) {
      //this.isLoading = true;
      return this.$api
        .put(`user/${this.user_id}/permissions/data`, payload)
        .then(response => {
          // this.isLoading = false

          this.$form.makeToastInfo(response.message);

          return true;
        })
        .catch(error => {
          this.isLoading = false;

          this.$form.makeToastError(error.message);

          return false;
        });
    },
    async updateTerritoriesPermissions() {
      let self = this;

      try {
        let payload = {
          territories: this.territoriesTree.value
        };

        // this.isLoading = true

        let error, response;

        [error, response] = await this.$to(
          this.$api.put(`user/${this.user_id}/permissions/territories`, payload)
        );

        if (error) throw new Error(error);

        this.$form.makeToastInfo(response.message);

        return response;
      } catch (error) {
        self.$form.makeToastError(error.message);
      } finally {
        this.isLoading = false;
      }
    },
    save(_mode) {
      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      return this.updateDataPermissions()
        .then(this.updateTerritoriesPermissions())
        .then(this.updateGroupsPermissions())
        .then(() => {
          this.saveInProgress = true;
          this.saveAndViewInProgress = true;

          //self.$form.makeToastInfo(response.message);

          return this.user_id;
        });
    },

    updateControlsState() {
      let _readonly = false;

      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        _readonly = false;
      }

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        _readonly = true;
      }

      for (var prop in this.controls) {
        if (Object.prototype.hasOwnProperty.call(this.controls, prop)) {
          this.controls[prop].readonly = _readonly;
        }
      }
    },
    async toggleDataPermissions() {
      let confirm = false;
      if (this.dataPermissionsCheckAll) {
        confirm = await this.$form.showConfirmation(
          "Are you sure want to reset all countries?"
        );
        if (confirm) {
          this.placesTree.value = [];

          this.dataPermissionsCheckAll = false;
          this.saveDataPermissions([]);
        }
      } else {
        confirm = await this.$form.showConfirmation(
          "Are you sure want to select all countries?"
        );

        if (confirm) {
          this.dataPermissionsCheckAll = true;

          this.placesTree.value = [];
          for (let item of this.placesTree.options) {
            this.placesTree.value.push(item.id);
          }
          let data = [];

          this.placesTree.options.forEach(v => {
            data.push({ country: v.label });
          });

          this.saveDataPermissions({
            data: data
          });
        }
      }
    }
  },
  watch: {
    mode() {
      this.updateControlsState();
    },
    id(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.initialize();

        //this.initialize(newValue)
      }
    }
  }
};
</script>

<style scoped>
.h-400 {
  height: 400px;
}

.treeselect-container >>> .vue-treeselect__multi-value {
  height: 100px !important;
  overflow: auto;
}

.treeselect >>> .vue-treeselect__menu {
  height: 300px !important;
}
</style>
